<template>
	<div class="box-style">
		<headerNav title="合同管理" leftNav="返回" @leftClick="back"></headerNav>
		<div class="content-style">
			<div class="pullRefresh-style">
				<div class="item-style">
					<div class="item-img">
						<imgPreview :src="info.fileAdd" />
					</div>
					<div class="item-text">
						<div class="item-label">上传人：</div>
						<div class="item-value">{{ info.createId }}</div>
					</div>
					<div class="item-text">
						<div class="item-label">上传时间：</div>
						<div class="item-value">{{ info.createDate }}</div>
					</div>
					<div class="item-text">
						<div class="item-label">备注信息：</div>
						<div class="item-value">{{ info.remark }}</div>
					</div>
				</div>
			</div>
		</div>
		<upBtn />
	</div>
</template>

<script>
import upBtn from './upBtn.vue'
import imgPreview from '@/components/imgPreview'
export default {
	components: {
		upBtn,
		imgPreview
	},
	data() {
		return {
			info: {}
		}
	},
	methods: {
		back() {
			this.$router.push({ name: "deepenUserInfoContractManage", })
		},
		// 下拉刷新
		onRefresh() {
		},
	},
	mounted() {
		this.info = JSON.parse(sessionStorage.getItem('deepenUserInfoContractManageDetail'))
	},
	beforeDestroy() {
		this.info = {}
	}
}
</script>

<style scoped lang="scss">
.box-style {

	.content-style {
		overflow: auto;
		text-align: left;
		height: calc(100vh - 50px);

		.item-style {
			margin: 16px 8px;
			padding: 10px;
			background: #FFFFFF;
			border-radius: 4px;

			.item-img {
				width: 100%;
				height: 140px;
				border-radius: 4px;
				overflow: hidden;
			}

			.item-text {
				margin-top: 6px;
				font-size: 15px;
				font-weight: 400;
				color: #333333;
				display: flex;
				font-size: 14px;

				&:nth-child(2) {
					margin-top: 12px;
				}

				.item-label {
					color: #888;
				}

				.item-value {
					flex: 1;
					word-wrap: break-word;
					word-break: break-all;
				}
			}
		}
	}
}
</style>
